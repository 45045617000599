.inner_comp_div{
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    height: calc(100% - 10vh);
    overflow-y: scroll;
    align-items: center;
}

.bank_card_div{
    background: rgba(118, 118, 128, 0.24);
    width: 460px;
    display: flex;
    align-items: center;
    border-radius: 30px;
    flex-direction: column;
    min-height: 70vh;
    height: max-content;
    width: 70vh;
}
.bank_card_head{
    border-bottom: 2px solid rgba(255, 255, 255, 0.08);
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}

.bank_card_div .main-img{
    width: 100%;
    max-height: 50vh;
}
.head_content_div{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    color: white;
}
.nike_head{
    font-size: 20px;
    font-weight: 500;
}
.sub_head{
    font-size: 14px;
    font-weight: 400;
}
.nike_logo_div{
    background-color: white;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.nike_logo_div img{
    height: 100%;
    width: 100%;
}

.back_card_body{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 1.5rem;
    color: white;
    height: 100%;
    justify-content: flex-end;
    flex: 1;
}
.bank_para_div{
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}
.location_div{
    display: flex;
    flex-direction: row;
}
.map_div{
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}
.headquart_div{
    font-size: 12px;
    font-weight: 500;
}
.location_name{
    font-size: 14px;
    font-weight: 400;
}
.social_icon{
    height: 45px;
    width: 45px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.04);
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.insta_div{
    height: 65px;
    display: flex;
    flex-direction: row;
    background: rgba(255, 255, 255, 0.04);
    padding: 10px;
    border-radius: 22px;
    align-items: center;
    margin-top: 1rem;
}
.insta_content{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
.like_dislike_btns_div{
    display: flex;
    margin: 1rem 0rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.like_btn{
    width: 40%;
    height: 41px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
}
.reportdiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal__body1{
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    margin: 0rem 1rem;
}
.modal-description{
    line-height: 34px;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
  }
.teaxtarea_style{
    width: 425px;
    height: 100px;
    margin: 0px 0px 1rem 7px;
    outline: none;
    border: none;
    border-radius: 5px;
    background: rgba(54, 62, 78, 1);
    color: white;
    padding: 10px;
}
.modal_btn_style{
    width: 425px!important;
    margin: 0px 0px 0px 7px;
}
@media (max-width: 700px) {
    .bank_card_head{
        padding: 0.5rem;
    }
    .head_content_div{
        margin-left: 0.5rem;
    }
    .card-img{
        max-height: 60vh;
    }
    .social_icon {
        margin-top: 0rem;
    }
    .back_card_body{
        padding: 0.5rem 1rem;
    }
    .inner_comp_div{
        height: calc(100% - 20vh);
    }
}